/* Base styling for body */
body {
  font-family: "Arial", sans-serif;
  background-color: #f5f5f5;
  color: #333;
  margin: 0;
  padding: 0;
  transition: background-color 0.3s ease, color 0.3s ease;
}

body.dark {
  background-color: #1e1e1e;
  color: #f0f0f0;
}

/* Container */
.container {
  width: 90%;
  /* max-width: 1200px; */
  margin: 40px auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

body.dark .container {
  background-color: #2b2b2b;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
}

/* Title */
.title {
  text-align: center;
  font-size: 2.5em;
  margin-bottom: 20px;
  color: #333;
  transition: color 0.3s ease;
}

body.dark .title {
  color: #f0f0f0;
}

/* Theme toggle button */
.theme-toggle {
  display: block;
  margin: 0 auto 20px auto;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 1em;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.theme-toggle:hover {
  background-color: #0056b3;
}

body.dark .theme-toggle {
  background-color: #444;
}

body.dark .theme-toggle:hover {
  background-color: #666;
}

/* Table styling */
table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
  font-size: 1em;
}

table thead {
  background-color: #007bff;
  color: white;
}

table thead th {
  padding: 15px;
  text-align: left;
}

table tbody tr {
  background-color: white;
  transition: background-color 0.3s ease;
}

body.dark table tbody tr {
  background-color: #2b2b2b;
}

table tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}

body.dark table tbody tr:nth-child(even) {
  background-color: #333;
}

table tbody td {
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

body.dark table tbody td {
  border-bottom: 1px solid #444;
}

table tbody tr:hover {
  background-color: #f1f1f1;
}

body.dark table tbody tr:hover {
  background-color: #444;
}

/* Input and textarea styling */
input[type="text"],
textarea {
  width: 100%;
  padding: 8px;
  background-color: inherit; /* Make the input blend with background */
  color: inherit;
  border: 1px solid transparent; /* Remove border for blending */
  border-radius: 5px;
  font-size: 1em;
  transition: border-color 0.3s ease, background-color 0.3s ease;
}

input[type="text"]:focus,
textarea:focus {
  border-color: #007bff;
  background-color: white; /* Light up when focused */
}

body.dark input[type="text"],
body.dark textarea {
  background-color: #2b2b2b; /* Dark mode blend */
  color: #f0f0f0;
  border-color: #444;
}

body.dark input[type="text"]:focus,
body.dark textarea:focus {
  background-color: #444;
  border-color: #007bff;
}

input {
  background-color: #2b2b2b;
  color: #f0f0f0;
  border-color: #444;
  border: 1px solid #444;
  border-radius: 5px;
  padding: 5px;
}

/* Buttons styling */
button {
  padding: 10px 15px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin: 5px;
}

button:hover {
  background-color: #0056b3;
}

button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

body.dark button {
  background-color: #2d7c3b;
}

body.dark button:hover {
  background-color: #666;
}

button:disabled:hover {
  background-color: #ccc;
}

/* Dial and Hang Up buttons styling */
button.dial-btn {
  background-color: #28a745;
  font-weight: bold;
  font-size: 1.2em;
}

button.dial-btn:hover {
  background-color: #218838;
}

button.hangup-btn {
  background-color: #dc3545;
  font-weight: bold;
  font-size: 1.2em;
}

button.hangup-btn:hover {
  background-color: #c82333;
}

body.dark button.dial-btn {
  background-color: #28a745;
}

body.dark button.dial-btn:hover {
  background-color: #218838;
}

body.dark button.hangup-btn {
  background-color: #dc3545;
}

body.dark button.hangup-btn:hover {
  background-color: #c82333;
}

/* Save status */
.save-status {
  margin-bottom: 20px;
  font-size: 1em;
  text-align: center;
  color: #666;
  transition: color 0.3s ease;
}

body.dark .save-status {
  color: #ccc;
}

/* Responsive design */
@media (max-width: 768px) {
  .container {
    padding: 10px;
  }

  table thead th,
  table tbody td {
    padding: 10px;
  }
}

.grayed-out {
  background-color: red !important; /* Light gray background */
  color: #222; /* Text color for grayed-out effect */
}

.timezone-tabs {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-bottom: 20px;
}

.timezone-tab {
  /* background-color: #2ecc71; */
  color: white;
  /* padding: 10px; */
  border-radius: 5px;
  /* margin: 10px; */
  cursor: pointer;
  flex-basis: 12%; /* Adjust this value to fit more/less in a row */
  text-align: center;
  font-size: 16px;
}

.timezone-tab.greyed-out {
  background-color: #bdc3c7;
  color: black;
}

.call-window {
  text-align: center;
  margin-bottom: 20px;
}

/* Popup Overlay Styles */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it's on top of everything */
}

.popup-content {
  background: #222;
  padding: 20px;
  border-radius: 10px;
  max-width: 600px;
  width: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  z-index: 1001;
  position: relative;
}

.popup-content {
  padding: 20px;
  border-radius: 10px;
  width: 100%; /* Wider popup */
  max-height: 90vh; /* Maximum height */
  overflow-y: auto; /* Enable scrolling */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  position: relative;
}

.popup-content h3 {
  margin-top: 0;
}

.popup-actions {
  margin-top: 20px;
  text-align: right;
}

.popup-actions button {
  margin-left: 10px;
}

.grayed-out {
  background-color: lightgray;
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* App.css */

.overlay-container {
  display: flex;
  flex-direction: row;
}

.script-column {
  flex: 1;
  margin-right: 20px;
  overflow-y: auto;
  max-height: 80vh;
}

.notes-column {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.notes-column textarea {
  flex: 1;
  resize: vertical;
  margin-bottom: 10px;
}

.call-info {
  margin-top: 10px;
}

.popup-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.popup-content {
  max-width: 97%;
  max-height: 90%;
  overflow-y: auto;
}

#total-dials-container {
  margin-left: auto;
  margin-right: auto;
  display: block;
  width: 600px;
  text-align: center;
  padding: 10px 0;
  border-style: solid;
  border-color: #444;
  border-radius: 20px;
  border-width: 1px;
  margin-bottom: 20px;
}
